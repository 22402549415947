<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Profile
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Mobile number
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Count
          </s-text>
        </v-col>
      </v-row>
      <user-referral-card
        v-for="referral in orderedReferrals"
        :key="referral.user_id"
        :id="referral.user_id"
        :count="referral.count"
      />
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import UserReferralCard from '@/components/cards/user/UserReferralCard'
export default {
  name: 'Referrals',
  components: {
    loader: Loader,
    'user-referral-card': UserReferralCard,
  },
  computed: {
    ...mapGetters({
      referrals: 'referral/referrals',
    }),
    orderedReferrals: function () {
      if (!this.referrals) return null

      const dataset = this.referrals
      const result = dataset.sort((a, b) => b.count - a.count)

      return result
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getReferrals () {
      this.isLoading = true
      this.$store
        .dispatch('referral/getReferrals')
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  watch: {
    referrals: {
      handler () {
        if (this.referrals) return
        this.getReferrals()
      },
      immediate: true,
    },
  },
}
</script>
